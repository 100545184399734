import { Component } from "@angular/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

/*#################################

Footer, incluye información de la empresa.

#################################*/

@Component({
    templateUrl: "footer.component.html",
    selector: "footer-component",
    styleUrls: ["footer.component.scss"]
})
export class FooterComponent {
    config: typeof config
    logo: string
    logosExtras: any[]
    hasSocial: boolean
    socials: any
    enableLogoFooter: boolean =
        config.app.baseStyle?.footer?.enableLogo !== undefined ? config.app.baseStyle?.footer?.enableLogo : true

    itemsMenu: any[]
    menuItemsAlumnos = [
        {
            name: "Practica",
            route: "/evaluaciones/recomendadas",
            icon: "prueba"
        },
        {
            name: "Resultados",
            route: "/pruebas/resultados",
            icon: "resultados"
        },
        {
            name: "Planes de clase",
            route: "/plan_clases",
            icon: "planificacion-o"
        },
        {
            name: "Dudas del curso",
            route: "/dudas",
            icon: "dialogos"
        }
    ]

    menuItemsProfesores = [
        {
            name: "Evaluar",
            route: "/pruebas/generar_prueba",
            icon: "editar-prueba"
        },
        {
            name: "Corregir",
            route: "/evaluacion_instancias/subir",
            icon: "upload"
        },
        {
            name: "Resultados",
            route: "/evaluaciones/resultados",
            icon: "resultados"
        },
        {
            name: "Planes de clase",
            route: "/plan_clases",
            icon: "planificacion-o"
        },
        {
            name: "Mis noticias",
            route: "/noticias",
            icon: "megafono-o"
        }
    ]

    menuItemsByApp = {
        alumnos: this.menuItemsAlumnos,
        profesores: this.menuItemsProfesores
    }

    constructor() {
        this.config = config
        this.logo = this.config.app.baseStyle?.footer?.logo
            ? this.config.app.assets[this.config.app.baseStyle?.footer?.logo]
            : this.config.app.assets.logoMediumLight
        this.logosExtras = this.config.app.baseStyle?.footer?.logosExtras
            ? this.config.app.baseStyle?.footer?.logosExtras
            : []
        this.hasSocial =
            this.config.plataforma.info?.social && Object.keys(this.config.plataforma.info.social).length >= 0
        this.socials = this.config.plataforma.info?.social
    }

    ngOnInit(): void {
        if (this.config.app.navigationByApp && this.config.app.navigationByApp[this.config.app.name]) {
            this.itemsMenu = this.config.app.navigationByApp[this.config.app.name]
        } else {
            this.itemsMenu = this.menuItemsByApp[this.config.app.name] || this.menuItemsAlumnos
        }
    }
}
