<footer>
    <div class="footer-wrap clearfix">
        <div class="row">
            <div class="col-md-4 col-sm-6 footer-col">
                <h3>Navegación</h3>
                <ul>
                    <ng-container *ngFor="let item of itemsMenu">
                        <li>
                            <a [routerLink]="item.route" role="button">
                                {{ item.name }}
                            </a>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>
    </div>
</footer>
