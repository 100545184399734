<ll-titulo>Bienvenid@</ll-titulo>

<div class="row">
    <div class="col-md-4 col-sm-6">
        <usuario-showcasebox></usuario-showcasebox>
        <br />
    </div>
    <div class="col-md-4 col-sm-6">
        <grupo-usuarios-profesor-showcase></grupo-usuarios-profesor-showcase>
        <br />
        <lista-establecimientos-profesor></lista-establecimientos-profesor>
        <br />
    </div>
</div>
<noticias-modal [perfiles]="'todos,profesores'"></noticias-modal>