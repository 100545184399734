import { DudaEditComponent, DudaNewComponent, DudaComponent, DudasComponent } from "@puntaje/puntaje/core"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

import { EditDudaGuard, BanGuard } from "@puntaje/puntaje/services"

export const routes: Routes = [
    // { path: "dudas/new", component: DudaNewComponent, canActivate: [TokenGuard, AuthGuard, BanGuard] },
    // { path: "dudas", component: DudasComponent, canActivate: [TokenGuard, AuthGuard, BanGuard] },
    // { path: "dudas/:id", component: DudaComponent, canActivate: [TokenGuard, AuthGuard, BanGuard] },
    // {
    //     path: "dudas/:id/edit",
    //     component: DudaEditComponent,
    //     data: {
    //         identifier: "id",
    //         baseRoute: "dudas"
    //     },
    //     canActivate: [TokenGuard, AuthGuard, EditDudaGuard, BanGuard]
    // }
]

export const routing = RouterModule.forChild(routes)
