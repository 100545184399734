import {
    ClasePlanClaseComponent,
    MisPlanClasesComponent,
    PlanClaseComponent,
    PlanClaseEditComponent,
    PlanClaseNewComponent,
    PlanClasesComponent
} from "@puntaje/puntaje/core"
import { NgModule } from "@angular/core"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"

const routes: Routes = [
    // {
    //     path: "plan_clases/new",
    //     component: PlanClaseNewComponent,
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: "plan_clases/:id/edit",
    //     component: PlanClaseEditComponent,
    //     canActivate: [AuthGuard]
    // },
    // { path: "plan_clases/:id", component: PlanClaseComponent, canActivate: [AuthGuard] },
    // { path: "plan_clases/:id/sesiones/:sesionId", component: ClasePlanClaseComponent, canActivate: [AuthGuard] },
    // { path: "plan_clases", component: MisPlanClasesComponent, canActivate: [AuthGuard] }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ProfesoresPlanClasesRoutingModule {}
