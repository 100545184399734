import { NoticiaComponent, NoticiasProfesorComponent } from "@puntaje/puntaje/core"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

export const routes: Routes = [
    // { path: "noticias/:id", component: NoticiaComponent, canActivate: [TokenGuard, AuthGuard] },
    // { path: "noticias", component: NoticiasProfesorComponent, canActivate: [AuthGuard] }
]

export const routing = RouterModule.forChild(routes)
