<div class="contenedor">
    <!-- Login -->
    <div id="section1">
        <div class="caja caja-inicio">
            <div class="fading-image" [lazyLoad]="landingImage" [offset]="200"></div>
            <div class="caja-inner-margin">
                <h1 class="titulo" *ngIf="titulo">{{ titulo }}</h1>
                <login-standard
                    class="login"
                    [enableContactUs]="false"
                    [enableRegister]="enableRegister"
                    [enableFacebookLogin]="false"
                    [enablePasswordRecovery]="true"
                ></login-standard>
                <br />
            </div>
        </div>
    </div>
</div>
<footer>
    <div class="footer-wrap clearfix">
        <div class="row">
            <div class="col-md-12 footer-col text-center">
                <h3 class="contactanos">Contáctanos</h3>
                <ul>
                    <li>
                        <p>
                            <fa name="envelope-o"></fa>
                            &nbsp;{{ config.plataforma.info.contacto.mail }}
                        </p>
                    </li>
                </ul>
            </div>

            <!-- <div class="col-md-4 col-md-offset-4 col-sm-6 footer-col">
                <h3>Encuéntranos en redes sociales</h3>
                <ul class="socialMedia">
                    <ng-container *ngFor="let key of socials | keys">
                        <li *ngIf="socials[key]">
                            <a [href]="socials[key]" [class]="key" target="_blank" role="button">
                                <fa [name]="key"></fa>
                            </a>
                        </li>
                    </ng-container>
                </ul>
            </div> -->
        </div>
    </div>
</footer>
