<ng-container *ngIf="!saved && form">
    <form novalidate [formGroup]="form">
        <div class="row">
            <div class="col-md-6">
                <h3>Datos Personales</h3>
                <form-input [form]="form" [params]="params['id']" [(value)]="usuario['id']" [key]="'id'"></form-input>
                <div>
                    <div>
                        <label class="label-completado">Nombre</label>
                        <p class="valor-completado">{{ usuario.nombre }}</p>
                    </div>
                    <div>
                        <label class="label-completado">Apellido Paterno</label>
                        <p class="valor-completado">{{ usuario.apellido_paterno }}</p>
                    </div>
                    <div>
                        <label class="label-completado">Apellido Materno</label>
                        <p class="valor-completado">{{ usuario.apellido_materno | easyplaceholder: "-" }}</p>
                    </div>
                    <div>
                        <label class="label-completado">CURP</label>
                        <p class="valor-completado">{{ usuario["usuario_" + pais].numero_unico }}</p>
                    </div>
                </div>

                <h3>Datos de la Cuenta</h3>
                <form-input
                    [form]="usuarioPaisForm"
                    [params]="usuarioPaisParams['usuario_id']"
                    [(value)]="usuario['usuario_' + pais]['usuario_id']"
                    [key]="'usuario_id'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['email']"
                    [(value)]="usuario['email']"
                    [key]="'email'"
                ></form-input>
                <form-input
                    [form]="usuarioPaisForm"
                    [params]="usuarioPaisParams['telefono']"
                    [(value)]="usuario['usuario_' + pais]['telefono']"
                    [key]="'telefono'"
                    [withLabel]="false"
                >
                    <label for="telefono" [attr.for]="'telefono'">
                        Teléfono celular
                        <fa
                            [name]="'question-circle-o'"
                            [tooltip]="
                                'Te recomendamos agregar tu celular para que puedas activar o recuperar tu cuenta fácilmente a través de SMS.'
                            "
                        ></fa>
                        <span class="required-mark"></span>
                    </label>
                </form-input>
            </div>

            <div class="col-md-6">
                <form-input
                    [form]="form"
                    [params]="params['password']"
                    [enablePasswordBar]="true"
                    [(value)]="usuario['password']"
                    [key]="'password'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['password_confirmation']"
                    [(value)]="usuario['password_confirmation']"
                    [key]="'password_confirmation'"
                ></form-input>
                <form-input
                    [form]="form"
                    [params]="params['terminos_y_condiciones']"
                    [(value)]="usuario['terminos_y_condiciones']"
                    [key]="'terminos_y_condiciones'"
                ></form-input>

                <button (click)="checkTelefono()" type="button" class="btn btn-default btn-block submit-btn">
                    Actualizar
                </button>
                <button (click)="back()" type="button" class="btn btn-default btn-block cancel-btn">
                    Volver a la página de Inicio
                </button>
            </div>
        </div>
    </form>
</ng-container>

<ng-container *ngIf="saved && mailChanged">
    Hemos enviado un e-mail a tu correo electrónico con instrucciones para validar tu cuenta. Si no te llega en 5
    minutos más, por favor revisa tu carpeta de spam.
</ng-container>

<ng-container *ngIf="saved && !mailChanged">
    Tus datos han sido actualizados correctamente. En unos segundos te redirigiremos a la página principal.
</ng-container>

<ng-container *ngIf="openModal">
    <generic-modal [buttonClicked]="openModal">
        <modal-titulo>Advertencia</modal-titulo>
        <modal-contenido>
            <p>Te sugerimos agregar tu celular para recuperar tu contraseña con SMS.</p>
        </modal-contenido>
        <modal-botones>
            <button class="btn btn-default" (click)="genericModal.close()">Agregar Celular</button>
            <button class="btn btn-default" (click)="goOmitir()">Omitir</button>
        </modal-botones>
    </generic-modal>
</ng-container>
