import {
    AmazonType,
    ApiType,
    AppConfiguration,
    AppType,
    AsignaturaType,
    CargaNominaConf,
    EvaluacionTipoMap,
    HojaRespuestaType,
    PlataformaType
} from "@puntaje/shared/core"

export class BaseConfig implements AppConfiguration {
    navegacion = {
        urlHistorial: ["/evaluaciones/resultados/historial"]
    }

    generar = {
        generar: true,
        intrumentosRecomendados: false,
        pruebasPropias: false,
        buscador: true
    }

    registro = {
        enableCarreras: null,
        universidades: [],
        enableTelefono: false,
        enableNivel: null,
        enableColegio: null
    }
    app = {
        assets: {
            logoLarge: "assets/img/logos/logoSquare.png",
            logoMedium: "assets/img/logos/logoSquare.png",
            logoSmall: "assets/img/logos/logoSquare.png",
            logoLargeLight: "assets/img/logos/logoSquare.png",
            logoMediumLight: "assets/img/logos/logoSquare.png",
            logoSmallLight: "assets/img/logos/logoSquare.png",
            logoSquare: "assets/img/logos/logoSquare.png",
            logoSquareLight: "assets/img/logos/logoSquare.png",
            streamingOffline: ""
        },
        name: "profesores",
        titles: {
            profesores: "Evaluaciones ANUIES",
            alumnos: "Sustentantes evaluaciones ANUIES"
        },
        loader: "aprendolibre2",
        routeUser: null,
        routeUserInfo: null,
        paths: {
            pendingInfo: "/pending_info",
            landing: "/landing"
        },
        baseStyle: {
            navbar: {
                logo: "logoSmall",
                logoMobile: "logoSquare"
            },
            footer: {
                enableLogo: false
            },
            landing: {
                backgroundImage: "assets/img/logos/anuies_fondo.jpg",
                logo: "logoLarge",
                subtitle: "Evaluaciones ANUIES",
                subtitleHasShadow: true,
                logoFooter: null
            },
            sharedLayout: {
                backgroundImage: "assets/img/logos/anuies_fondo.jpg",
                logo: "logoLarge"
            }
        },
        navigationByApp: {
            alumnos: [
                {
                    icon: "editar-prueba",
                    name: "Ejercita",
                    route: "/pruebas/generar_prueba"
                },
                {
                    icon: "prueba",
                    name: "Practica",
                    route: "/evaluaciones/recomendadas"
                },
                {
                    icon: "resultados",
                    name: "Resultados",
                    route: "/pruebas/resultados"
                }
            ],
            profesores: [
                {
                    icon: "editar-prueba",
                    name: "Evaluar",
                    route: "/pruebas/generar_prueba"
                },
                {
                    icon: "upload",
                    name: "Corregir",
                    route: "/evaluacion_instancias/subir"
                },
                {
                    icon: "resultados",
                    name: "Resultados",
                    route: "/evaluaciones/resultados"
                }
            ]
        }
    } as AppType

    amazon = {
        s3BucketUri: "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/",
        s3BucketUriNebu: "https://s3-us-west-1.amazonaws.com/bucket-nebuloso/"
    } as AmazonType

    api = {
        checkTokenPath: "/check_token",
        authPath: "/authenticate"
    } as ApiType

    asignaturas = [
        {
            asignatura: "Certificación",
            icono: "libro",
            clase: "asignatura1",
            abreviacion: "Certificación",
            asignaturaPlataforma: "Certificación",
            evaluacionTipo: ["Anuies"],
            materialVisible: true
        }
    ] as AsignaturaType[]

    plataforma = {
        name: "ANUIES",
        id: null,
        pais: "mexico",
        lugarTipo: "Municipio",
        lugarLabel: "Municipio",
        identificadorUsuario: ["numero_unico"],
        identificadorEstablecimiento: ["codigo_unico"],
        grupoUsuarioAlias: "curso",
        clasificacionTipoNivel: "curso",
        info: {
            social: {
                facebook: "https://www.facebook.com/anuies",
                twitter: "https://twitter.com/ANUIES/",
                youtube: "https://www.youtube.com/channel/UCmncYoYPjmtmnHALiiqdTSQ",
                instagram: null
            },
            soporte: {
                mail: null,
                telefono: null
            },
            contacto: {
                mail: "campus.virtual@anuies.mx",
                telefono: null
            },
            companyName: "Evaluaciones ANUIES",
            companySite: "https://www.evaluaciones-anuies.mx/"
        },
        evaluacionDefault: "Anuies",
        resumenGeneral: {
            curriculum: "curricular colombia",
            generadores: [83, 15, 16, 17, 18, 84, 19, 20, 85]
        },
        tiposInstrumentoInformeUso: [],
        evaluacionTipos: ["Anuies"],
        añoInicial: 2020,
        periodos: [
            {
                label: "",
                fechaInicial: [0, 1],
                fechaFinal: [11, 31, 23, 59, 59]
            }
        ],
        streamingDefault: null,
        iconSet: {
            noticiasShowcase: "megafono-o",
            establecimientosShowcase: "establecimiento-o",
            cursosShowcase: "comunidad-o"
        },
        instrumentoMensualTipo: "curricular colombia",
        tiposNotificacion: [
            {
                tipo: "curricular mexico",
                label: "Pruebas"
            },
            {
                tipo: "duda",
                label: "Dudas"
            },
            {
                tipo: "respuesta_correcta_duda",
                label: "Respuestas Correctas Dudas"
            },
            {
                tipo: "respuesta_duda",
                label: "Respuestas Dudas"
            },
            {
                tipo: "retroalimentacion",
                label: "Retroalimentaciones"
            }
        ],
        sesionAlias: null,
        evaluacionTipoAdaptiveDefault: "ensayo",
        ordenAlternativasAleatorias: true,
        evaluacionSecuencial: {
            restrictedNavigation: true,
            autoDudaNoContestada: true
        },
        smowl: {
            cameraEndpoint: "https://swl.smowltech.net/monitor/controller.php",
            registrationEndpoint: "https://swl.smowltech.net/monitor/controllerReg.php",
            registrationPath: "/home"
        }
    } as PlataformaType

    evaluaciones = {
        ensayo: {
            alias: "ensayo",
            cursos: [
                {
                    label: "7º Medio",
                    clasificacion: "Séptimo Básico"
                },
                {
                    label: "8º Medio",
                    clasificacion: "Octavo Básico"
                },
                {
                    label: "1º Medio",
                    clasificacion: "Primero Medio"
                },
                {
                    label: "2º Medio",
                    clasificacion: "Segundo Medio"
                },
                {
                    label: "3º Medio",
                    clasificacion: "Tercero Medio"
                },
                {
                    label: "4º Medio",
                    clasificacion: "Cuarto Medio"
                }
            ],
            baseUrl: "/evaluaciones",
            cursoTipo: "curso",
            estrellas: [
                {
                    max: 266,
                    min: 150
                },
                {
                    max: 383,
                    min: 267
                },
                {
                    max: 500,
                    min: 384
                },
                {
                    max: 617,
                    min: 501
                },
                {
                    max: 734,
                    min: 618
                },
                {
                    max: 850,
                    min: 735
                }
            ],
            cursosMaterial: [],
            clasificaciones: {
                groupBy: ["eje temático,sub eje tematico", "curso PSU", "habilidad cognitiva", "mención"],
                methods: "clasificaciones",
                estadisticas: ["eje temático", "sub eje tematico"],
                referencesTo: {
                    "sub eje tematico": "eje temático"
                },
                configuration: [
                    {
                        name: "Eje temático",
                        htmlId: "eje",
                        hasHijos: true,
                        clasificacionTipo: "eje temático"
                    },
                    {
                        name: "Curso",
                        htmlId: "curso",
                        hasHijos: false,
                        clasificacionTipo: "curso PSU"
                    },
                    {
                        name: "Habilidad Cognitiva",
                        htmlId: "hc",
                        hasHijos: false,
                        clasificacionTipo: "habilidad cognitiva"
                    },
                    {
                        name: "Mención",
                        htmlId: "mencion",
                        hasHijos: false,
                        clasificacionTipo: "mención"
                    }
                ],
                cambiarPregunta: ["eje temático", "sub eje tematico"],
                desempenoEvaluacion: "eje temático,sub eje tematico",
                estadisticasEvolutivas: "eje temático,sub eje tematico",
                clasificacionTiposTablaEspecificaciones: ["eje temático", "habilidad cognitiva"]
            },
            cantidadPreguntas: 5,
            rangoCalificacion: {
                max: 850,
                min: 150,
                tickInterval: 50
            },
            clasificacionMaterial: {
                label: "eje",
                clasificacion: "eje temático"
            },
            streamingChannelAlias: "Clases PSU",
            clasificacionDistribucion: "ejes",
            clasificacionTiposEstadisticas: [
                {
                    nombre: "eje temático",
                    alumnos: true,
                    compuesto: true,
                    profesores: true,
                    informeAnual: true
                },
                {
                    nombre: "sub eje tematico",
                    alumnos: false,
                    compuesto: true,
                    profesores: true,
                    informeAnual: false
                },
                {
                    nombre: "habilidad cognitiva",
                    alumnos: false,
                    compuesto: true,
                    profesores: true,
                    informeAnual: true
                }
            ],
            nivelTipoInstrumentoInformeFinal: ["1º Medio", "2º Medio", "3º Medio", "4º Medio"],
            planPersonalClasificacionMaterial: {},
            planPersonalClasificacionMateriales: []
        },
        curricular: {
            alias: "prueba",
            cursos: [
                {
                    label: "7º Medio",
                    clasificacion: "Séptimo Básico"
                },
                {
                    label: "8º Medio",
                    clasificacion: "Octavo Básico"
                },
                {
                    label: "1º Medio",
                    clasificacion: "Primero Medio"
                },
                {
                    label: "2º Medio",
                    clasificacion: "Segundo Medio"
                },
                {
                    label: "3º Medio",
                    clasificacion: "Tercero Medio"
                },
                {
                    label: "4º Medio",
                    clasificacion: "Cuarto Medio"
                }
            ],
            baseUrl: "/evaluaciones",
            estrellas: [
                {
                    max: 2,
                    min: 1
                },
                {
                    max: 3,
                    min: 2
                },
                {
                    max: 4,
                    min: 3
                },
                {
                    max: 5,
                    min: 4
                },
                {
                    max: 6,
                    min: 5
                },
                {
                    max: 7,
                    min: 6
                }
            ],
            cursoTipo: "curso",
            cursosMaterial: [
                {
                    id: 7,
                    label: "7º Básico",
                    clasificacion: "Séptimo Básico"
                },
                {
                    id: 8,
                    label: "8º Básico",
                    clasificacion: "Octavo Básico"
                },
                {
                    id: 9,
                    label: "1º Medio",
                    clasificacion: "Primero Medio"
                },
                {
                    id: 10,
                    label: "2º Medio",
                    clasificacion: "Segundo Medio"
                },
                {
                    id: 11,
                    label: "3º Medio",
                    clasificacion: "Tercero Medio"
                },
                {
                    id: 12,
                    label: "4º Medio",
                    clasificacion: "Cuarto Medio"
                }
            ],
            clasificaciones: {
                groupBy: [
                    "eje temático LGE,objetivo de aprendizaje",
                    "unidad LGE,objetivo de aprendizaje",
                    "unidad LOCE,aprendizaje esperado"
                ],
                methods: "clasificaciones",
                estadisticas: ["eje temático LGE"],
                referencesTo: {
                    "objetivo de aprendizaje": "curso"
                },
                configuration: [
                    {
                        name: "Eje de contenidos",
                        htmlId: "eje",
                        hasHijos: true,
                        clasificacionTipo: "eje temático LGE"
                    },
                    {
                        name: "Unidad",
                        htmlId: "unidadlge",
                        hasHijos: true,
                        clasificacionTipo: "unidad LGE"
                    },
                    {
                        name: "Unidad",
                        htmlId: "unidad",
                        hasHijos: true,
                        clasificacionTipo: "unidad LOCE"
                    }
                ],
                desempenoEvaluacion: "eje temático LGE",
                estadisticasEvolutivas: "eje temático LGE",
                clasificacionTiposTablaEspecificaciones: ["objetivo de aprendizaje", "habilidad cognitiva LGE"],
                cambiarPregunta: []
            },
            hideEstadistica: false,
            cantidadPreguntas: 4,
            rangoCalificacion: {
                max: 400,
                min: 0,
                tickInterval: 25
            },
            clasificacionMaterial: {
                label: "eje",
                clasificacion: "eje temático LGE"
            },
            streamingChannelAlias: "Clases Curriculares",
            clasificacionDistribucion: "ejes",
            clasificacionTiposEstadisticas: [
                {
                    nombre: "eje temático LGE",
                    alumnos: true,
                    compuesto: false,
                    profesores: true,
                    informeAnual: true
                },
                {
                    nombre: "aprendizaje esperado",
                    alumnos: false,
                    compuesto: false,
                    profesores: false,
                    informeAnual: true
                }
            ],
            nivelTipoInstrumentoInformeFinal: ["1º Medio", "2º Medio", "3º Medio", "4º Medio"],
            planPersonalClasificacionMaterial: {},
            planPersonalClasificacionMateriales: []
        },
        Anuies: {
            alias: "prueba",
            cursos: [
                {
                    label: "7º Medio",
                    clasificacion: "Séptimo Básico"
                },
                {
                    label: "8º Medio",
                    clasificacion: "Octavo Básico"
                },
                {
                    label: "1º Medio",
                    clasificacion: "Primero Medio"
                },
                {
                    label: "2º Medio",
                    clasificacion: "Segundo Medio"
                },
                {
                    label: "3º Medio",
                    clasificacion: "Tercero Medio"
                },
                {
                    label: "4º Medio",
                    clasificacion: "Cuarto Medio"
                }
            ],
            baseUrl: "/evaluaciones",
            estrellas: [
                {
                    max: 2,
                    min: 1
                },
                {
                    max: 3,
                    min: 2
                },
                {
                    max: 4,
                    min: 3
                },
                {
                    max: 5,
                    min: 4
                },
                {
                    max: 6,
                    min: 5
                },
                {
                    max: 7,
                    min: 6
                }
            ],
            cursoTipo: "curso",
            cursosMaterial: [
                {
                    id: 7,
                    label: "7º Básico",
                    clasificacion: "Séptimo Básico"
                },
                {
                    id: 8,
                    label: "8º Básico",
                    clasificacion: "Octavo Básico"
                },
                {
                    id: 9,
                    label: "1º Medio",
                    clasificacion: "Primero Medio"
                },
                {
                    id: 10,
                    label: "2º Medio",
                    clasificacion: "Segundo Medio"
                },
                {
                    id: 11,
                    label: "3º Medio",
                    clasificacion: "Tercero Medio"
                },
                {
                    id: 12,
                    label: "4º Medio",
                    clasificacion: "Cuarto Medio"
                }
            ],
            clasificaciones: {
                groupBy: ["Eje_ANUIES", "Tema_ANUIES", "Subtema_ANUIES"],
                methods: "clasificaciones",
                estadisticas: ["Eje_ANUIES"],
                referencesTo: {
                    "objetivo de aprendizaje": "curso"
                },
                configuration: [
                    {
                        name: "Eje",
                        htmlId: "eje",
                        hasHijos: true,
                        clasificacionTipo: "Eje_ANUIES"
                    },
                    {
                        name: "Tema",
                        htmlId: "unidadlge",
                        hasHijos: true,
                        clasificacionTipo: "Tema_ANUIES"
                    },
                    {
                        name: "subtema",
                        htmlId: "unidad",
                        hasHijos: true,
                        clasificacionTipo: "Subtema_ANUIES"
                    }
                ],
                desempenoEvaluacion: "eje temático LGE",
                estadisticasEvolutivas: "eje temático LGE",
                clasificacionTiposTablaEspecificaciones: ["objetivo de aprendizaje", "habilidad cognitiva LGE"],
                cambiarPregunta: []
            },
            hideEstadistica: false,
            cantidadPreguntas: 4,
            rangoCalificacion: {
                max: 400,
                min: 0,
                tickInterval: 25
            },
            clasificacionMaterial: {
                label: "eje",
                clasificacion: "eje temático LGE"
            },
            streamingChannelAlias: "Clases Curriculares",
            clasificacionDistribucion: "ejes",
            clasificacionTiposEstadisticas: [
                {
                    nombre: "eje temático LGE",
                    alumnos: true,
                    compuesto: false,
                    profesores: true,
                    informeAnual: true
                },
                {
                    nombre: "aprendizaje esperado",
                    alumnos: false,
                    compuesto: false,
                    profesores: false,
                    informeAnual: true
                }
            ],
            nivelTipoInstrumentoInformeFinal: ["1º Medio", "2º Medio", "3º Medio", "4º Medio"],
            planPersonalClasificacionMaterial: {},
            planPersonalClasificacionMateriales: []
        }
    } as EvaluacionTipoMap

    instrumentosPredefinidos = {
        zonas: ["Columna 1", "Columna 2", "Columna 3"]
    }

    hojaRespuesta = {
        sectores: [
            [1, 2],
            [2, 3],
            [3, 4],
            [1, 6],
            [6, 3],
            [3, 8],
            [5, 6],
            [6, 7],
            [7, 8],
            [5, 9],
            [9, 10]
        ],
        alternativas: 4,
        rutFormaByMarkers: {
            14: {
                rutMarkerUp: 13,
                rutMarkerBottom: 14,
                rutCols: 4,
                rutRows: 10,
                formaMarkerUp: 11,
                formaMarkerBottom: 12,
                formaCols: 4,
                formaRows: 10
            }
        },
        dimSectoresByMarkers: {
            14: {
                "1,2": { cols: 4, rows: 10 },
                "2,3": { cols: 4, rows: 10 },
                "3,4": { cols: 4, rows: 10 },
                "1,6": { cols: 4, rows: 10 },
                "6,3": { cols: 4, rows: 10 },
                "3,8": { cols: 4, rows: 10 },
                "5,6": { cols: 4, rows: 10 },
                "6,7": { cols: 4, rows: 10 },
                "7,8": { cols: 4, rows: 10 },
                "5,9": { cols: 4, rows: 10 },
                "9,10": { cols: 4, rows: 5 },
                "11,12": { cols: 4, rows: 10, inverted: true },
                "13,14": { cols: 4, rows: 10, inverted: true }
            }
        },
        alignByMarkers: {
            14: [1, 5]
        },
        validMarkers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
    } as HojaRespuestaType

    mensajesLogin = {
        idPlaceholder: "Email o NUIP",
        invalidLoginId: "Debes incluir un correo electrónico o NUIP válido.",
        invalidLoginMessage1:
            "<strong>La combinación de correo electrónico/contraseña o NUIP/contraseña es incorrecta.</strong> Las contraseñas de ",
        invalidLoginMessage2:
            ' distinguen entre mayúsculas y minúsculas. Por favor, comprueba la tecla "Bloq Mayús". También puedes probar a borrar el caché y las cookies de tu navegador.'
    }

    cargaNomina = {
        nomina: {
            alumnos: "NominaAlumnoAnuies"
        }
    } as CargaNominaConf
}
